<template>
  <div>
    <h1 class="static-title">개인정보보호정책</h1>
    <div class="static-desc">
      <p>
        디안트보르트(이하 "회사"라 함)는 회사가 운영하는 제주투브이알(이하
        "사이트"라 함)를 이용하는 이용자들의 개인정보보호를 매우 중요시하며,
        이용자가 회사의 웹서비스(이하 "서비스"라 함)를 이용함과 동시에
        온라인상에서 회사에 제공한 개인정보가 보호 받을 수 있도록 최선을 다하고
        있습니다. 이에 회사는 통신비밀보호법, 전기통신사업법,
        정보통신망이용촉진등에관한법률 등 정보통신서비스제공자가 준수하여야 할
        관련 법규상의 개인정보보호 규정 및 정보통신부가 제정한
        개인정보보호지침을 준수하고 있습니다. 회사는 개인정보 보호정책을 통하여
        이용자들이 제공하는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며
        개인정보보호를 위해 어떠한 조치가 취해지고 있는지 알려드립니다.
      </p>
      <p>
        회사는 개인정보 보호정책을 사이트 첫 화면에 공개함으로써 이용자들이
        언제나 용이하게 보실 수 있도록 조치하고 있습니다.
      </p>
      <p>
        회사의 개인정보 보호정책은 정부의 법률 및 지침 변경이나 회사의 내부 방침
        변경 등으로 인하여 수시로 변경될 수 있고, 이에 따른 개인정보 보호정책의
        지속적인 개선을 위하여 필요한 절차를 정하고 있습니다. 그리고 개인정보
        보호정책을 개정하는 경우 회사는 그 변경사항에 대하여 즉시 사이트를
        통하여 게시하고 버전번호 및 개정일자 등을 부여하여 개정된 사항을
        이용자들이 쉽게 알아볼 수 있도록 하고 있습니다. 이용자들께서는 사이트
        방문시 수시로 확인하시기 바랍니다.
      </p>
      <p>회사의 개인정보 보호정책은 다음과 같은 내용을 담고 있습니다.</p>
      <p>
        <span class="static-subtitle">가. 개인정보 수집에 대한 동의</span>
        회사는 이용자들이 회사의 개인정보 보호정책 또는 이용약관의 내용에 대하여
        「동의함」버튼 또는 「동의안함」버튼을 클릭할 수 있는 절차를 마련하여,
        「동의함」버튼을 클릭하면 개인정보 수집에 대해 동의한 것으로 봅니다.
      </p>
      <p>
        <span class="static-subtitle">나. 개인정보의 수집목적 및 이용목적</span>
        "개인정보"라 함은 생존하는 개인에 관한 정보로서 당해 정보에 포함되어
        있는 성명, 주민등록번호 등의 사항에 의하여 당해 개인을 식별할 수 있는
        정보(당해 정보만으로는 특정 개인을 식별할 수 없더라도 다른 정보와
        용이하게 결합하여 식별할 수 있는 것을 포함)를 말합니다. 대부분의 회사
        서비스는 별도의 사용자 등록이 없이 언제든지 사용할 수 있습니다. 그러나
        회사는 회원서비스를 통하여 이용자들에게 맞춤식 서비스를 비롯한 보다 더
        향상된 양질의 서비스를 제공하기 위하여 이용자 개인의 정보를 수집하고
        있습니다.
      </p>
      <p>
        <span class="static-subtitle">다. 개인정보 항목 및 수집방법</span>
        회사는 이용자들이 회원서비스를 이용하기 위해 회원으로 가입하실 때 서비스
        제공을 위한 필수적인 정보들을 온라인상에서 입력 받고 있습니다. 회원
        가입시에 받는 필수적인 정보는 성명, 주민등록번호, 주소, 전화번호
        등입니다. 또한 양질의 서비스 제공을 위하여 이용자들이 선택적으로 입력할
        수 있는 사항으로서 회사주소, 회사전화번호, 직업, 이메일주소 및 이메일
        수신여부 항목을 입력 받고 있습니다.
      </p>
      <p>
        <span class="static-subtitle">라. 개인정보의 보유 및 이용기간</span>
        이용자가 회사의 회원으로서 회사에 제공하는 서비스를 이용하는 동안 회사는
        이용자들의 개인정보를 계속적으로 보유하며 서비스 제공 등을 위해
        이용합니다. 다만, 아래의 "마. 이용자 자신의 개인정보 관리(열람,정정,삭제
        등)에 관한 사항" 에서 설명한 절차와 방법에 따라 회원 본인이 직접
        삭제하거나 수정한 정보, 가입해지를 요청한 경우에는 재생할 수 없는 방법에
        의하여 디스크에서 완전히 삭제하며 추후 열람이나 이용이 불가능한 상태로
        처리됩니다.
      </p>
      <p>
        <span class="static-subtitle"
          >마. 이용자 자신의 개인정보 관리(열람,정정,삭제 등)에 관한 사항</span
        >
        이용자는 언제든지 회사 홈페이지를 이용하여 회원등록되어 있는 자신의
        개인정보를 조회하거나 수정할 수 있으며 회원등록 탈퇴를 요청할 수도
        있습니다. 이용자들의 개인정보 조회 및 수정을 위해서는 사이트의 회원관리
        메뉴에서 아이디와 비밀번호를 사용하여 로그인(LOG-IN)하면 되는데,
        아이디(ID) 및 주민등록번호, 이름을 제외한 모든 입력사항을 수정할 수
        있습니다. 또한, 비밀번호를 잊어버린 경우에는 회원 로그인 메뉴 하단에
        있는 "패스워드찾기"를 클릭하여 본인 확인에 필요한 사항을 입력하시면,
        본인여부 확인 후 email을 통하여 비밀번호를 알려 드립니다. 회원등록
        탈퇴는 사이트에 있는 나의정보에서 "회원탈퇴"를 클릭하시면, 이용자
        본인여부를 확인한 후 처리합니다.
      </p>
      <p>
        <span class="static-subtitle">바. 쿠키(cookie)의 운영에 관한 사항</span>
        이용자들에게 특화된 맞춤서비스를 제공하기 위해서 회사는 이용자들의
        정보를 저장하고 수시로 불러오는 '쿠키(cookie)'를 사용합니다. 쿠키는
        웹사이트를 운영하는데 이용되는 서버(HTTP)가 이용자의 컴퓨터 브라우저에게
        보내는 소량의 정보이며 이용자들의 PC 컴퓨터내의 하드디스크에 저장되기도
        합니다. 이용자들이 회사에 접속한 후 로그인(LOG-IN)하여 마이페이지(My
        Page) 등의 서비스를 이용하기 위해서는 쿠키를 허용하셔야 합니다. 회사는
        이용자들에게 적합하고 보다 유용한 서비스를 제공하기 위해서 쿠키를
        이용하여 ID에 대한 정보를 찾아냅니다. 쿠키는 이용자의 컴퓨터는
        식별하지만 이용자를 개인적으로 식별하지는 않습니다. 쿠키를 이용하여
        이용자들이 방문한 사이트의 각 서비스와 이용형태, 이용자 규모 등을
        파악하여 더욱 더 편리한 서비스를 만들어 제공할 수 있고 이용자에게
        최적화된 정보를 제공할 수 있습니다. 이용자들은 쿠키에 대하여 사용여부를
        선택할 수 있습니다. 웹브라우저에서 옵션을 설정함으로써 모든 쿠키를
        허용할 수도 있고, 쿠키가 저장될 때마다 확인을 거치거나, 모든 쿠키의
        저장을 거부할 수도 있습니다. 다만, 쿠키의 저장을 거부할 경우에는
        로그인이 필요한 일부 서비스는 이용할 수 없습니다.
      </p>
      <p>
        <span class="static-subtitle">사. 개인정보관련 기술적-관리적 대책</span>
        회사는 이용자들의 개인정보를 취급함에 있어 개인정보가 분실, 도난, 누출,
        변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적 대책을
        강구하고 있습니다. 이용자들의 개인정보는 비밀번호에 의해 철저히 보호되고
        있습니다. 회원 아이디(ID)의 비밀번호는 본인만이 알고 있으며, 개인정보의
        확인 및 변경도 비밀번호를 알고 있는 본인에 의해서만 가능합니다. 따라서
        이용자 여러분께서는 비밀번호를 누구에게도 알려주시면 안됩니다. 이를 위해
        회사에서는 기본적으로 PC에서의 사용을 마치신 후 온라인상에서
        로그아웃(LOG-OUT)하시고 웹브라우저를 종료하도록 권장합니다. 특히 다른
        사람과 PC를 공유하여 사용하거나 공공장소(학교, 도서관, 인터넷 게임방
        등)에서 이용한 경우에는 개인정보가 다른 사람에게 알려지는 것을 막기 위해
        위와 같은 절차가 더욱 필요할 것입니다. 회사는 해킹이나 컴퓨터 바이러스
        등에 의해 회원의 개인정보가 유출되거나 훼손되는 것을 막기 위해 최선을
        다하고 있습니다. 개인정보의 훼손에 대비해서 자료를 수시로 백업하고 있고,
        최신 백신프로그램을 이용하여 이용자들의 개인정보나 자료가 누출되거나
        손상되지 않도록 방지하고 있으며, 암호알고리즘 등을 통하여 네트워크상에서
        개인정보를 안전하게 전송할 수 있도록 하고 있습니다. 그리고
        침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있으며, 기타
        시스템적으로 안정성을 확보하기 위한 가능한 모든 기술적 장치를 갖추려
        노력하고 있습니다.
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.static-title {
  margin-bottom: 50px;
  font-size: 24px;
  font-weight: 700;
}
.static-subtitle {
  display: block;
  font-size: 18px;
  font-weight: 700;
}
</style>
